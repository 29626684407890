/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import 'scrollbar.scss';

span, a, p, div, h1, h2, h3, h4, h5{
  font-family: 'Poppins' !important;
}


/* You can add global styles to this file, and also import other style files */

*{
  font-family: 'Poppins';
  /* outline: 1px solid red; */
}

button:focus{
  outline: none !important;
}

.container{
  margin-left: auto !important;
  margin-right: auto !important;
}

.grecaptcha-badge {
  /* position: fixed;
  right: 0;
  bottom: 10px; */
  margin-left: auto;
  margin-right: auto;
}

#recaptcha-token {
  position: absolute;
  top: 100;
}

.my-class {
  color: white;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}


input[type='checkbox']:focus {
  --tw-ring-inset: #000 !important;
  --tw-ring-offset-width: 0px !important;
  --tw-ring-offset-color: #000 !important;
  --tw-ring-color: #000 !important;
  --tw-ring-offset-shadow: #000 0 0 0 #000 #000 !important;
  --tw-ring-shadow: #000 0 0 0 calc(1px + var(--tw-ring-offset-width)) #000 !important;
}

input[type='checkbox']:active {
  --tw-ring-inset: #000 !important;
  --tw-ring-offset-width: 0px !important;
  --tw-ring-offset-color: #000 !important;
  --tw-ring-color: #000 !important;
  --tw-ring-offset-shadow: #000 0 0 0 #000 #000 !important;
  --tw-ring-shadow: #000 0 0 0 calc(1px + var(--tw-ring-offset-width)) #000 !important;
}


.btn-primary{
  @apply bg-blue-700 text-white;
}
.btn-outline-secondary{
  @apply bg-gray-100 text-gray-900;
}


.select__visibility .selectize-control .selectize-input {
  padding: 4px 8px !important;
}

.select__assignee .selectize-control .selectize-input {
  padding: 4px 8px !important;
}

.INACTIVE {
  background-color: gray;
}

.assignee-invalid .selectize-control .selectize-input {
  border: 1px solid red;
}

.ACTIVE {
  background-color: lightgreen;
}

.pac-container {
  z-index: 10000 !important;
}

.padding-1 {
  padding: 1rem !important;
}

.padding-l {
  padding-left: 1rem;
}

.padding-r {
  padding-right: 1rem;
}

.padding-t {
  padding-top: 1rem;
}

.padding-b {
  padding-bottom: 1rem;
}

.padding-top-none {
  padding-top: 0rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
}

.padding-bottom-none {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 0rem;
}

.padding-none {
  padding: 0;
}

.highlight {
  background-color: #ffc800;
  color: black;
}

.crm-border {
  border: 1px solid #c0c0c0 !important;
  background-color: #fff !important;
}

.crm-no-border {
  border: none !important;
  background-color: #fff !important;
}

.bg-pink-100{
  background-color: #EFCBF4 !important;
}

.text-pink-900{
  color: #9500A6;
}

/*checkbox
*/

*:before,
*:after {
  box-sizing: border-box;
}

.md-checkbox {
  position: relative;
}

.md-checkbox label {
  cursor: pointer;
}

.md-checkbox label:before,
.md-checkbox label:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
}

.md-checkbox label:before {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  cursor: pointer;
  transition: background 0.3s;
}

.md-checkbox input[type='checkbox'] {
  outline: 0;
  margin-right: 10px;
}

.md-checkbox input[type='checkbox']:checked + label:before {
  background: #337ab7;
  border: none;
}

.md-checkbox input[type='checkbox']:checked + label:after {
  transform: rotate(-45deg);
  top: 5px;
  left: 4px;
  width: 12px;
  height: 6px;
  border: 2px solid #fff;
  border-top-style: none;
  border-right-style: none;
}

/*Filters_items layout */
/* .filters_items .filters_item.active {
  border-bottom-color: #29292b;
  border-bottom: 1.5px solid;
}

.filters_items .filters_item a {
  text-decoration: none !important;
} */

/*Tab layout */

.dd-tab {
  align-items: center;
  border-bottom: 0.05rem solid #e7e9ed;
  display: flex;
  display: -ms-flexbox;
  -ms-flex-align: center;
  /* -ms-flex-wrap: wrap;
  flex-wrap: wrap; */
  list-style: none;
  margin: 0.2rem 0 0.15rem 0;
}

.dd-tab .dd-tab-item {
  margin-top: 0;
  cursor: pointer;
}

.dd-tab .dd-tab-item.active a,
.dd-tab .dd-tab-item a.active {
  border-bottom-color: #29292b;
  border-bottom: 1.5px solid;
}

.dd-tab:not(.dd-tab-block) .dd-badge {
  padding-right: 0;
}

.dd-tab .dd-tab-item a {
  /* font-size: 1.3rem; */
  border-bottom: 0.1rem solid transparent;
  color: inherit;
  display: block;
  margin: 0 0.4rem 0 0;
  padding: 0.4rem 1.2rem 0.3rem 1.2rem;
  text-decoration: none !important;
}

ul,
ol {
  margin: 0.8rem 0 0.8rem 0.8rem;
  padding: 0 !important;
}
.btn{
  font-family: serif !important;
}

.dd-badge[data-dd-badge]::after {
  font-size: 0.7rem;
  height: 0.9rem;
  line-height: 1;
  min-width: 0.9rem;
  padding: 0.1rem 0.2rem;
  text-align: center;
  white-space: nowrap;
}

.dd-badge[data-dd-badge]::after,
.dd-badge:not([data-dd-badge])::after {
  background: #5755d9;
  background-clip: padding-box;
  border-radius: 0.5rem;
  box-shadow: 0 0 0 0.1rem #fff;
  color: #fff;
  content: attr(data-dd-badge);
  display: inline-block;
  transform: translate(-0.1rem, -0.5rem);
}

/*
  * CSS TOGGLE SWITCH
  *
  * Ionuț Colceriu - ghinda.net
  * https://github.com/ghinda/css-toggle-switch
  *
  */

/* supported values are px, rem-calc, em-calc
   */

/* imports
   */

/* Functions
   */

/* Shared
   */

/* Hide by default
   */

.switch-toggle a,
.switch-light span span {
  display: none;
}

/* We can't test for a specific feature,
   * so we only target browsers with support for media queries.
   */

@media only screen {
  /* Checkbox
   */
  .switch-light {
    position: relative;
    display: block;
    /* simulate default browser focus outlines on the switch,
     * when the inputs are focused.
     */
  }
  .switch-light::after {
    clear: both;
    content: '';
    display: table;
  }
  .switch-light *,
  .switch-light *:before,
  .switch-light *:after {
    box-sizing: border-box;
  }
  .switch-light a {
    display: block;
    transition: all 0.2s ease-out;
  }
  .switch-light label,
  .switch-light > span {
    /* breathing room for bootstrap/foundation classes.
       */
    line-height: 2em;
  }
  .switch-light input:focus ~ span a,
  .switch-light input:focus + label {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;
    /* Chrome/Opera gets its native focus styles.
       */
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 0) {
  .switch-light input:focus ~ span a,
  .switch-light input:focus + label {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}

@media only screen {
  /* don't hide the input from screen-readers and keyboard access
   */
  .switch-light input {
    position: absolute;
    opacity: 0;
    z-index: 3;
  }
  .switch-light input:checked ~ span a {
    right: 0%;
  }
  /* inherit from label
   */
  .switch-light strong {
    font-weight: inherit;
  }
  .switch-light > span {
    position: relative;
    overflow: hidden;
    display: block;
    min-height: 2em;
    /* overwrite 3rd party classes padding
     * eg. bootstrap .alert
     */
    padding: 0;
    text-align: left;
  }
  .switch-light span span {
    position: relative;
    z-index: 2;
    display: block;
    float: left;
    width: 50%;
    text-align: center;
    user-select: none;
  }
  .switch-light a {
    position: absolute;
    right: 50%;
    top: 0;
    z-index: 1;
    display: block;
    width: 50%;
    height: 100%;
    padding: 0;
  }
  /* bootstrap 4 tweaks
  */
  .switch-light.row {
    display: flex;
  }
  .switch-light .alert-light {
    color: #333;
  }
  /* Radio Switch
   */
  .switch-toggle {
    position: relative;
    display: block;
    /* simulate default browser focus outlines on the switch,
     * when the inputs are focused.
     */
    /* For callout panels in foundation
    */
    padding: 0 !important;
    /* 2 items
     */
    /* 3 items
     */
    /* 4 items
     */
    /* 5 items
     */
    /* 6 items
     */
  }
  .switch-toggle::after {
    clear: both;
    content: '';
    display: table;
  }
  .switch-toggle *,
  .switch-toggle *:before,
  .switch-toggle *:after {
    box-sizing: border-box;
  }
  .switch-toggle a {
    display: block;
    transition: all 0.2s ease-out;
  }
  .switch-toggle label,
  .switch-toggle > span {
    /* breathing room for bootstrap/foundation classes.
       */
    line-height: 2em;
  }
  .switch-toggle input:focus ~ span a,
  .switch-toggle input:focus + label {
    outline-width: 2px;
    outline-style: solid;
    outline-color: Highlight;
    /* Chrome/Opera gets its native focus styles.
       */
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 0) {
  .switch-toggle input:focus ~ span a,
  .switch-toggle input:focus + label {
    outline-color: -webkit-focus-ring-color;
    outline-style: auto;
  }
}

@media only screen {
  .switch-toggle input {
    position: absolute;
    left: 0;
    opacity: 0;
  }
  .switch-toggle input + label {
    position: relative;
    z-index: 2;
    display: block;
    float: left;
    padding: 0 0.5em;
    margin: 0;
    text-align: center;
  }
  .switch-toggle a {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0;
    z-index: 1;
    width: 10px;
    height: 100%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(4),
  .switch-toggle label:nth-child(2):nth-last-child(4) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(4) ~ a {
    width: 50%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(4) ~ input:checked:nth-child(3) + label ~ a {
    left: 50%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(6),
  .switch-toggle label:nth-child(2):nth-last-child(6) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(6) ~ a {
    width: 33.33%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(6) ~ input:checked:nth-child(3) + label ~ a {
    left: 33.33%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(6) ~ input:checked:nth-child(5) + label ~ a {
    left: 66.66%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(8),
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ a {
    width: 25%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(3) + label ~ a {
    left: 25%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(5) + label ~ a {
    left: 50%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(8) ~ input:checked:nth-child(7) + label ~ a {
    left: 75%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(10),
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ a {
    width: 20%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(3) + label ~ a {
    left: 20%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(5) + label ~ a {
    left: 40%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(7) + label ~ a {
    left: 60%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(10) ~ input:checked:nth-child(9) + label ~ a {
    left: 80%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12),
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ label,
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ a {
    width: 16.6%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(3) + label ~ a {
    left: 16.6%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(5) + label ~ a {
    left: 33.2%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(7) + label ~ a {
    left: 49.8%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(9) + label ~ a {
    left: 66.4%;
  }
  .switch-toggle label:nth-child(2):nth-last-child(12) ~ input:checked:nth-child(11) + label ~ a {
    left: 83%;
  }
  /* Candy Theme
   * Based on the "Sort Switches / Toggles (PSD)" by Ormal Clarck
   * http://www.premiumpixels.com/freebies/sort-switches-toggles-psd/
   */
  .switch-toggle.switch-candy,
  .switch-light.switch-candy > span {
    background-color: #2d3035;
    border-radius: 3px;
    box-shadow: inset 0 2px 6px rgba(0, 0, 0, 0.3), 0 1px 0 rgba(255, 255, 255, 0.2);
  }
  .switch-light.switch-candy span span,
  .switch-light.switch-candy input:checked ~ span span:first-child,
  .switch-toggle.switch-candy label {
    color: #fff;
    font-weight: bold;
    text-align: center;
    text-shadow: 1px 1px 1px #191b1e;
  }
  .switch-light.switch-candy input ~ span span:first-child,
  .switch-light.switch-candy input:checked ~ span span:nth-child(2),
  .switch-candy input:checked + label {
    color: #333;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  }
  .switch-candy a {
    border: 1px solid #333;
    border-radius: 3px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2), inset 0 1px 1px rgba(255, 255, 255, 0.45);
    background-color: #70c66b;
    background-image: linear-gradient(rgba(255, 255, 255, 0.2), transparent);
  }
  .switch-candy-blue a {
    background-color: #38a3d4;
  }
  .switch-candy-yellow a {
    background-color: #f5e560;
  }
  /* iOS Theme
  */
  .switch-ios.switch-light span span {
    color: #888b92;
  }
  .switch-ios.switch-light a {
    left: 0;
    top: 0;
    width: 2em;
    height: 2em;
    background-color: #fff;
    border-radius: 100%;
    border: 0.25em solid #d8d9db;
    transition: all 0.2s ease-out;
  }
  .switch-ios.switch-light > span {
    display: block;
    width: 100%;
    height: 2em;
    background-color: #d8d9db;
    border-radius: 1.75em;
    transition: all 0.4s ease-out;
  }
  .switch-ios.switch-light > span span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    line-height: 1.875em;
    vertical-align: middle;
    transition: all 0.2s ease-out;
  }
  .switch-ios.switch-light > span span:first-of-type {
    opacity: 1;
    padding-left: 1.875em;
  }
  .switch-ios.switch-light > span span:last-of-type {
    padding-right: 1.875em;
  }
  .switch-ios.switch-light input:checked ~ span a {
    left: 100%;
    border-color: #4bd865;
    margin-left: -2em;
  }
  .switch-ios.switch-light input:checked ~ span {
    border-color: #4bd865;
    box-shadow: inset 0 0 0 30px #4bd865;
  }
  .switch-ios.switch-light input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .switch-ios.switch-light input:checked ~ span span:last-of-type {
    opacity: 1;
    color: #fff;
  }
  .switch-ios.switch-toggle {
    background-color: #d8d9db;
    border-radius: 30px;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
  }
  .switch-ios.switch-toggle a {
    background-color: #4bd865;
    border: 0.125em solid #d8d9db;
    border-radius: 1.75em;
    transition: all 0.12s ease-out;
  }
  .switch-ios.switch-toggle label {
    height: 2.4em;
    color: #888b92;
    line-height: 2.4em;
    vertical-align: middle;
  }
  .switch-ios input:checked + label {
    color: #3e4043;
  }
  /* Holo Theme
   */
  .switch-toggle.switch-holo,
  .switch-light.switch-holo > span {
    background-color: #464747;
    border-radius: 1px;
    box-shadow: inset rgba(0, 0, 0, 0.1) 0 1px 0;
    color: #fff;
    text-transform: uppercase;
  }
  .switch-holo label {
    color: #fff;
  }
  .switch-holo > span span {
    opacity: 0;
    transition: all 0.1s;
  }
  .switch-holo > span span:first-of-type {
    opacity: 1;
  }
  .switch-holo > span span,
  .switch-holo label {
    font-size: 85%;
    line-height: 2.15625em;
  }
  .switch-holo a {
    background-color: #666;
    border-radius: 1px;
    box-shadow: inset rgba(255, 255, 255, 0.2) 0 1px 0, inset rgba(0, 0, 0, 0.3) 0 -1px 0;
  }
  /* Selected ON switch-light
  */
  .switch-holo.switch-light input:checked ~ span a {
    background-color: #0e88b1;
  }
  .switch-holo.switch-light input:checked ~ span span:first-of-type {
    opacity: 0;
  }
  .switch-holo.switch-light input:checked ~ span span:last-of-type {
    opacity: 1;
  }
  /* Material Theme
   */
  /* switch-light
   */
  .switch-light.switch-material a {
    top: -0.1875em;
    width: 1.75em;
    height: 1.75em;
    border-radius: 50%;
    background: #fafafa;
    box-shadow: 0 0.125em 0.125em 0 rgba(0, 0, 0, 0.14), 0 0.1875em 0.125em -0.125em rgba(0, 0, 0, 0.2),
      0 0.125em 0.25em 0 rgba(0, 0, 0, 0.12);
    transition: right 0.28s cubic-bezier(0.4, 0, 0.2, 1);
  }
  .switch-material.switch-light {
    overflow: visible;
  }
  .switch-material.switch-light::after {
    clear: both;
    content: '';
    display: table;
  }
  .switch-material.switch-light > span {
    overflow: visible;
    position: relative;
    top: 0.1875em;
    width: 3.25em;
    height: 1.5em;
    min-height: auto;
    border-radius: 1em;
    background: rgba(0, 0, 0, 0.26);
  }
  .switch-material.switch-light span span {
    position: absolute;
    clip: rect(0 0 0 0);
  }
  .switch-material.switch-light input:checked ~ span a {
    right: 0;
    background: #3f51b5;
    box-shadow: 0 0.1875em 0.25em 0 rgba(0, 0, 0, 0.14), 0 0.1875em 0.1875em -0.125em rgba(0, 0, 0, 0.2),
      0 0.0625em 0.375em 0 rgba(0, 0, 0, 0.12);
  }
  .switch-material.switch-light input:checked ~ span {
    background: rgba(63, 81, 181, 0.5);
  }
  /* switch-toggle
   */
  .switch-toggle.switch-material {
    overflow: visible;
  }
  .switch-toggle.switch-material::after {
    clear: both;
    content: '';
    display: table;
  }
  .switch-toggle.switch-material a {
    top: 48%;
    width: 0.375em !important;
    height: 0.375em;
    margin-left: 0.25em;
    background: #3f51b5;
    border-radius: 100%;
    transform: translateY(-50%);
    transition: transform 0.4s ease-in;
  }
  .switch-toggle.switch-material label {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1em;
  }
  .switch-toggle.switch-material label:before {
    content: '';
    position: absolute;
    top: 48%;
    left: 0;
    display: block;
    width: 0.875em;
    height: 0.875em;
    border-radius: 100%;
    border: 0.125em solid rgba(0, 0, 0, 0.54);
    transform: translateY(-50%);
  }
  .switch-toggle.switch-material input:checked + label:before {
    border-color: #3f51b5;
  }
  /* ripple
   */
  .switch-light.switch-material > span:before,
  .switch-light.switch-material > span:after,
  .switch-toggle.switch-material label:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    display: block;
    width: 4em;
    height: 4em;
    border-radius: 100%;
    background: #3f51b5;
    opacity: 0.4;
    margin-left: -1.25em;
    margin-top: -1.25em;
    transform: scale(0);
    transition: opacity 0.4s ease-in;
  }
  .switch-light.switch-material > span:after {
    left: auto;
    right: 0;
    margin-left: 0;
    margin-right: -1.25em;
  }
  .switch-toggle.switch-material label:after {
    width: 3.25em;
    height: 3.25em;
    margin-top: -0.75em;
  }
  @keyframes materialRipple {
    0% {
      transform: scale(0);
    }
    20% {
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(1);
    }
  }
  .switch-material.switch-light input:not(:checked) ~ span:after,
  .switch-material.switch-light input:checked ~ span:before,
  .switch-toggle.switch-material input:checked + label:after {
    animation: materialRipple 0.4s ease-in;
  }
  /* trick to prevent the default checked ripple animation from showing
   * when the page loads.
   * the ripples are hidden by default, and shown only when the input is focused.
   */
  .switch-light.switch-material.switch-light input ~ span:before,
  .switch-light.switch-material.switch-light input ~ span:after,
  .switch-material.switch-toggle input + label:after {
    visibility: hidden;
  }
  .switch-light.switch-material.switch-light input:focus:checked ~ span:before,
  .switch-light.switch-material.switch-light input:focus:not(:checked) ~ span:after,
  .switch-material.switch-toggle input:focus:checked + label:after {
    visibility: visible;
  }
}

/* Bugfix for older Webkit, including mobile Webkit. Adapted from
   * http://css-tricks.com/webkit-sibling-bug/
   */

@media only screen and (-webkit-max-device-pixel-ratio: 2) and (max-device-width: 80em) {
  .switch-light,
  .switch-toggle {
    -webkit-animation: webkitSiblingBugfix infinite 1s;
  }
}

@-webkit-keyframes webkitSiblingBugfix {
  from {
    -webkit-transform: translate3d(0, 0, 0);
  }
  to {
    -webkit-transform: translate3d(0, 0, 0);
  }
}

/*# sourceMappingURL=toggle-switch.css.map */

.ngx-filter .datatable-header {
  height: auto !important;
}

.ngx-filter .datatable-header .filter-input {
  width: 100%;
  height: 25px;
  display: block;
  border: 1px solid #ced4da;
}

.ngx-filter .datatable-header .filter-label {
  height: 10px;
  cursor: pointer;
}

.ngx-datatable.material .datatable-header .datatable-header-cell {
  background-color: #cfd8dc;
}

.ngx-datatable.material-loadboard .datatable-header {
  @apply bg-white rounded-md p-2;
}

/* Grid Lines for Table */

.bordered .datatable-header-cell,
.bordered .datatable-body-cell {
  border-right: 1px solid #ddd !important;
}

.bordered .datatable-body-row {
  border-bottom: 1px solid #ddd !important;
}

[hidden] {
  display: none !important;
}

.alert-fixed {
  margin-bottom: 2px !important;
  width: 100%;
  z-index: 9999;
  border-radius: 0px;
}

.alert-fixed .close:before {
  content: '' !important;
}

/* Custom Badge  */

.badge-circle {
  /* width: 1.7rem; */
  /* color: white; */
  border-radius: 1rem;
  padding: 0 !important;
  font-size: 1rem;
}

.badge-circle-true {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 1rem;
  padding: 0 !important;
  margin-top: -0.2rem;
  background-color: lightgreen;
}
.badge-circle-false {
  width: 0.7rem;
  height: 0.7rem;
  border-radius: 1rem;
  padding: 0 !important;
  margin-top: -0.2rem;
  background-color: gray;
}
.badge-style {
  color: white;
  padding: 0px 4px;
  border-radius: 4px;
  border: unset;
}

.badge-outline {
  background-color: transparent;
  border: 1px solid rgb(0, 0, 0);
  color: #000;
}

.badge.badge-lg {
  font-size: 100%;
  font-weight: 500;
}

.badge.badge-sm {
  line-height: 1.3rem;
  min-width: 1.5rem;
  width: auto;
}

.badge-outline.badge-secondary {
  background-color: transparent;
  border: 1px solid rgb(0, 0, 0);
  color: #000;
}

.badge-outline.badge-success {
  background-color: transparent;
  border: 1px solid #23d160;
  color: #000;
}

.badge-outline.badge-danger {
  background-color: transparent;
  border: 1px solid #ff3860;
  color: #000;
}

.badge-outline.badge-warning {
  background-color: transparent;
  border: 1px solid #f57f17;
  color: #000;
}

.badge-outline.badge-info {
  background-color: transparent;
  border: 1px solid #209cee;
  color: #000;
}

.badge-outline.badge-light {
  background-color: transparent;
  border: 1px solid rgb(0, 0, 0);
  color: #000;
}

.badge-outline.badge-dark {
  background-color: transparent;
  border: 1px solid #000;
  color: #000;
}

.badge .user-status {
  display: inline-block;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  position: absolute;
  right: -3px;
  bottom: -2px;
}
.badge .user-status.online {
  background-color: #72de72;
}
.badge .user-status.offline {
  background-color: #f4516c;
}
.badge .user-status.afk {
  background-color: #efef00;
}

.color-silver {
  color: #b4b4b4;
}
.color-gold {
  color: #c9b037;
}

.stage {
  padding: 0 3px;
}
.stage-hot {
  color: #ff3860;
}
.stage-warm {
  color: #f57f17;
}
.stage-cold {
  color: #209cee;
}
.stage-new {
  color: #333;
}

.stage-order {
  color: #23d160;
}

.badge-status-all {
  background-color: #333;
}
.badge-status-new {
  background-color: #fde8e8;
  color: #b91c1c;
}
.badge-status-new i {
  color: #f05252;
}
.badge-status-on-hold {
  background-color: #f3decb;
  color: #964803;
}
.badge-status-order {
  background-color: #def7ec;
  color: #03543f;
}

.badge-status-order i {
  color: #0e9f6e;
}
.badge-status-billed {
  background-color: #6a1b9a;
}
.badge-status-paid {
  background-color: #00c853;
}
.badge-status-dispatched {
  background-color: #00d1b2;
}
.badge-status-picked-up {
  background-color: #fdf6b2;
  color: #725b13;
}
.badge-status-picked-up i {
  color: #e3a008;
}
.badge-status-picked-up-old {
  background-color: #209cee;
}
.badge-status-delivered {
  background-color: #e1effe;
  color: #1e429f;
}
.badge-status-delivered i {
  color: #3f83f8;
}
.badge-status-delivered-tesla {
  background-color: #ff3860;
}
.badge-status-closed {
  background-color: #4e342e;
}
.badge-status-cancelled {
  background-color: #f3f4f6;
  color: #111928;
}
.badge-status-cancelled i {
  color: #737374;
}
.badge-status-completed {
  background-color: #00c853;
}
.badge-status-claims {
  background-color: #dc3545;
}
.badge-status-sent {
  background-color: #e65100;
}
.bg-green {
  background-color: #5eba00 !important;
}
.bg-primary {
  background-color: #467fcf !important;
}

/* DATATABLE */
.ngx-datatable.material .datatable-header .datatable-header-cell {
  /* background-color: #ccc; */
  font-weight: bold;
}

.datatable-body-cell-label {
  font-size: 0.9rem;
}

/* --blue: #467fcf;
--indigo: #6574cd;
--purple: #a55eea;
--pink: #f66d9b;
--red: #cd201f;
--orange: #fd9644;
--yellow: #f1c40f;
--green: #5eba00;
--teal: #2bcbba;
--cyan: #17a2b8;
--white: #fff;
--gray: #868e96;
--gray-dark: #343a40;
--azure: #45aaf2;
--lime: #7bd235;
--primary: #467fcf;
--secondary: #868e96;
--success: #5eba00;
--info: #45aaf2;
--warning: #f1c40f;
--danger: #cd201f;
--light: #f8f9fa;
--dark: #343a40; */

/* Timeline */
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 4px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 0;
  width: 10px;
  height: 10px;
  z-index: 400;
}
/* SELECTIZE CONTROL */
.contact-search .selectize-dropdown {
  top: auto !important;
}
.contact-search .selectize-control .selectize-dropdown .contact-dropdown:last-child {
  border-bottom: 0;
}
.contact-search .selectize-control .selectize-dropdown .contact-dropdown {
  padding: 5px;
  border-bottom: 1px solid rgba(151, 151, 151, 0.12);
  white-space: nowrap;
}
.contact-search .selectize-control .selectize-dropdown .company {
  display: block;
}
.contact-search .selectize-control .selectize-dropdown .name {
  font-size: 15px;
  display: block;
}

.contact-search .selectize-control .selectize-dropdown > div {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

/* Style for Expend div */

.grow-collapse {
  max-height: 63px;
  transition: max-height 0.5s ease-out;
  -webkit-transition: max-height 0.5s ease-out;
  overflow: hidden;
}

.grow:hover .grow-collapse {
  max-height: 500px;
}
.grow-actions {
  border-top: 1px solid #c0c0c0;
  text-align: center;
}
.grow-actions i {
  font-size: 12px;
}
.grow:hover .grow-actions {
  display: none;
}

.shortcut-buttons-flatpickr-button {
  display: inline-block;
  font-weight: 400;
  color: #007bff;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.shortcut-buttons-flatpickr-button:focus {
  outline: 0;
  text-decoration: underline;
  box-shadow: none;
}

.shortcut-buttons-flatpickr-button:hover {
  color: #0056b3;
}

.shortcut-buttons-flatpickr-button:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.modal-content {
  background: #f2f3f8 !important;
  width: 90%;
}

.contact_box .contact__wrapper {
  margin: 0 !important;
}
.contact_box-claim .contact__wrapper .contact {
  margin: 10px;
  border: 1px solid #d4d4c5;
}
#onesignal-bell-container {
  display: none;
}
/* #onesignal-bell-container.onesignal-reset.onesignal-bell-container-bottom-right{
  top:55px;
  bottom:auto !important;
} */

.dropdown-menu.show.ng-star-inserted {
  max-height: 500px;
  overflow-y: scroll;
  color: #000;
  border: none;
}

.dropdown-menu.show.ng-star-inserted .active {
  background: #EBF5FF;
  color: #000;
}

.dropdown-menu>.dropdown-item, .dropdown-menu>li>a {
  color: #000;
}

app-note-search .dropdown-menu.show.ng-star-inserted {
  width: 450px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
/* For floating labels */
.dropdown-btn{
  padding: 0 !important ;
  border: none !important;
}
.dropdown-multiselect__caret{
  top: -8px !important;
}

.modal-md {
  max-width: 512px !important;
}

/* .modal-content {
  border-radius: 50px !important;
} */

/* .modal-dialog {
  max-height: 90%;
  overflow: hidden;
} */

@media screen and (max-width: 800px) {
  .modal-dialog {
    max-width: none !important;
    width: 980px;
    overflow: auto;
  }
}

@media (max-width: 991px) {
  .container {
    padding-right: 3px !important;
    padding-left: 3px !important;
  }
}

.cornerFloat {
  position: absolute;
  top: -5px;
  right: -11px;
}

/* .res-list-link {
  font-weight: bold;
  font-size: 1.2em;
} */
.dropdown-menu.show {
  min-width: auto;
}
.dropdown .dropdown-toggle:empty:after {
  display: none;
}
.dropdown-list {
  overflow: hidden;
}

.font-12 {
  font-size: 12px;
}
.white-space-nowrap {
  white-space: nowrap;
}
.order-hot-info-container {
  align-items: center;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}
.note-count-icon {
  line-height: 0;
  cursor: pointer;
}
.note-count-icon i.far.fa-sticky-note {
  font-size: 22px;
}
.note-count-icon .notes-count {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 14px;
  font-size: 14px;
}

.contact-modal {
  max-width: 700px !important;
}
.address-modal {
  max-width: 500px !important;
}

.filler-requests-modal{
  max-width: 680px !important;
}


.order-acc-drop {
  transform: translateX(-10vw) !important;
}
/* Floating label */
.floating-input-form {
  position: relative;
  display: flex;
  width: 100%;
  border: #eaedf0 2px solid;
  border-radius: 7px;
  height: 39px;
}

.floating-input-form__input {
    font-family: sans-serif, Arial !important;
    display: inline;
    max-width: 100%;
    width: 100%;
    position: relative;
    top: 0;
    left: 0;
    border: 0 !important;
    font-size: 1rem;
    color: black;
    outline: none;
    padding: 13px 5px 2px 0px;
    box-shadow: none !important;
    background: #f7f7f7;
    border-radius: 7px;
}

.floating-input-form__input::placeholder {
  opacity: 0;
}
.floating-input-form__input:focus::placeholder {
      opacity: 1;
}

.floating-input-form__label {
    pointer-events: none;
    position: absolute;
    left: 0.6rem;
    top: 0.4rem;
    font-size: 0.9rem;
    font-weight: 400 !important;
    color: #6c757d;
    cursor: text;
    transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
}

.jss1{
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0 8px;
  overflow: hidden;
  position: absolute;
  /* border-radius: 0.25rem; */
  pointer-events: none !important;
  /* border-bottom: 1px solid #e1e5ee;  */
  outline: none!important;
}
.jss3{
  width: auto;
  height: 11px;
  display: block;
  padding: 0;
  font-size: 0.9rem;
  max-width: 0.01px;
  text-align: left;
  transition: max-width 50ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  visibility: hidden;
}
.jss3 > span {
  display: inline-block;
  left:1rem;
  padding-right: 8px;
}

.floating-input-form__input:focus ~ .jss1, .floating-input-form__input:focus ~ .floating-input-form__label{
  border-color: #80bdff;
  color:#80bdff;
}

.floating-input-form__input:focus ~ .jss1 .jss3,
.floating-input-form__input:not(:placeholder-shown).floating-input-form__input:not(:focus) ~ .jss1 .jss3{
    max-width: 1000px;
    transition: max-width 300ms cubic-bezier(0.0, 0, 0.2, 1) 150ms;

}

.floating-input-form__input:focus ~ .floating-input-form__label,
.floating-input-form__input:not(:placeholder-shown).floating-input-form__input:not(:focus) ~ .floating-input-form__label {
  top: -17px;
  /* left: 1rem; */
  font-weight: 500;
}

.form-control, .form-control[readonly] {
  @apply pl-[30px];
  border: none;
  height: 40.5px;
}

.form-control, .form-control {
  @apply text-[13px];
}

.floating-input-form .form-control{
  border: none !important;
  padding-left: 0 !important;
}
.form-control.is-invalid ~ .jss1{
  border-bottom: solid #dc3545 !important;
}

.modal-content {
  /* background-color: #0056b3 !important; */
}
/* End floating label */
.removeBtn {
  display: block;
  position: absolute !important;
  z-index: 3;
  right: 3px;
  top: -2px;
}
.modal .modal-content .modal-header, .modal-body, .modal-footer{
  background: white;
  /* border-radius: 10px; */

}
.modal{
  z-index: 1055 !important;
}
/* Return  Styles*/
.btn-success {
  color: #fff;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.btn-info {
  color: #fff;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}

.btn-outline-success {
  color: #28a745 !important;
  background-color: transparent;
  background-image: none;
  border-color: #28a745 !important;
}
.btn-outline-success:hover {
  color: #fff !important;
  background-color: #28a745 !important;
  border-color: #28a745 !important;
}
.btn-outline-info {
  color: #17a2b8 !important;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8 !important;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8 !important;
  border-color: #17a2b8 !important;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active{
  color: #fff;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}
.btn-group-sm>.btn{
  padding: 0.25rem 0.5rem
}
.badge-secondary {
  color: #fff;
  background-color: #6c757d !important;
}
.badge-success {
  color: #fff;
  background-color: #28a745 !important;
  border: 1px solid #28a745 !important;
}
.badge-danger {
  font-weight: bold;
  color: #9B1C1C !important;
  background-color: #FDE8E8 !important;
  border: 1px solid #FDE8E8 !important;
}
.badge-primary{
  color: #fff;
  background-color: #007bff !important;
}
a, .pages{
  text-decoration: none !important;
}
a:hover{
  text-decoration: underline !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}
.textcomplete-dropdown{
  z-index: 2000 !important;
}
.textcomplete-item {
  padding: 2px 5px;
  cursor: pointer;
}
.textcomplete-item:hover,
.textcomplete-item.active {
  background-color: #cdcdcd;
}


/* ::-webkit-scrollbar {
  width: 6px;
  overflow-y: scroll;
  background: grey;
  box-shadow: inset 0 0 4px #707070;
}

::-webkit-scrollbar-thumb {
  background: rgb(177, 177, 177);
  border-radius: 10px;
} */
textarea{
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}


/* app-loadboard-list ::-webkit-scrollbar {
  width: 6px;
  overflow-x: scroll;
  background: grey;
  box-shadow: inset 0 0 4px #707070;
} */

.notification-modal{
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
}

.split-orders-modal {
  /* max-width: 1100px;
  margin-left: auto; map-modal map-modal
  margin-right: auto; */
  border-radius: 10px;
}

.location-modal  {
  border-radius: 10px !important;
  height: 90% !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.map-modal {
  border-radius: 10px !important;
  height: 90% !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.contact-modal{
  border-radius: 10px !important;
}


.merge-accounts-modal {
  border-radius: 8px !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.edit-address-modal  {
  width: 300px !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.create-route-modal  {
  border-radius: 8px !important;
  width: 476px !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.equipment-form-modal  {
  border-radius: 10px !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.candidate-form-modal  {
  border-radius: 8px !important;
  width: 416px !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.custom-bol-modal  {
  margin-top: 5vh;
  border-radius: 8px !important;
  /* width: 95% !important; */
  margin-left: auto !important;
  margin-right: auto!important;
}

.custom-bol-modal {
  margin-top: 5vh;
  border-radius: 8px !important;
  /* width: 95% !important; */
  margin-left: auto !important;
  margin-right: auto!important;
}

.new-account-modal {
  border-radius: 10px !important;
  width: 370px !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.follow-up-modal {
  border-radius: 10px !important;
  width: 855px !important;
  margin-left: auto !important;
  margin-right: auto!important;
  background: white;
}

.shop-details-modal {
  border-radius: 10px !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.create-claim-modal  {
  border-radius: 10px !important;
}

.account-create-modal {
  border-radius: 10px !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.company-role-modal {
  border-radius: 10px !important;
  width: 85% !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.claim-adjusters-notes-modal {
  border-radius: 10px !important;
  width: 1210px !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.claim-shops-nearby-modal  {
  border-radius: 10px !important;
  width: 1300px !important;
  margin-left: auto !important;
  margin-right: auto!important;
}

.order-booking-counter-modal{
  border-radius: 10px !important;
  width: 534px !important;
  margin-left: auto !important;
  margin-right: auto!important;
}
.carrier-order-booking-modal{
  border-radius: 10px !important;
  width: 1465px !important;
  margin-left: auto !important;
  margin-right: auto!important;
  padding-bottom: 20px !important;
}

.carrier-order-booking-modal-form-only{
  border-radius: 10px !important;
  width: 597px !important;
  margin-left: auto !important;
  margin-right: auto!important;
  padding-bottom: 20px !important;
}

.create-contact-modal {
  border-radius: 8px !important;
  width: fit-content !important;
  margin-left: auto !important;
  margin-right: auto !important;
  transition: 0.3s;
}
.public-update-status  {
  border-radius: 8px !important;
  width: 518px !important;
  margin-left: auto !important;
  margin-right: auto!important;
  transition: 0.3s;
}

.expense-bulk-update-modal {
  border-radius: 8px !important;
  width: 818px !important;
  margin-left: auto !important;
  margin-right: auto!important;
  transition: 0.3s;
}

app-view-request-modal {
  height: 100% !important;
}

.view-request-modal .modal-dialog.modal-lg {
  max-width: 100% !important;
  margin-top: 0px !important;
  padding-top: 16px !important;
  height: calc(100% - 32px);
  /* margin-right: 61px !important; */
  /* border-radius: 8px !important; */
  /* width: 485px !important; */
  /* margin-left: auto !important;
  margin-right: auto !important; */
}

.view-request-modal  {
  border-radius: 8px !important;
  width: 495px !important;
  margin-left: auto !important;
  margin-right: 61px !important;
  height: 100%;
}

@media(max-width: 1800px) {
  .shop-details-modal  {
    width: 92% !important;
  }
}

.editor_full .note-editor.note-frame .note-editing-area .note-editable, .note-editor.note-airframe .note-editing-area .note-editable {
  padding: 10px;
  overflow: auto;
  word-wrap: break-word;
  height: 293px !important;
}

.editor_full_email .note-editor.note-frame .note-editing-area .note-editable, .note-editor.note-airframe .note-editing-area .note-editable {
  height: 218px !important;
}

app-view-location-modal google-map .map-container {
  border-radius: 15px;
}

.aditor-mass-notication app-editor .note-editor .note-toolbar {
  display: none;
}

.aditor-mass-notication app-editor .note-editor .note-editing-area {
  min-height: 200px;
  background-color: rgb(249, 250, 251);
  border-radius: 0.5rem;
}

.aditor-mass-notication app-editor .note-editor.note-frame {
  border: 1px solid rgb(209, 213, 219);
  border-radius: 0.5rem;
}

.wrapper_container {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}
/*
.sort-btn {
  background-color: #666666;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
} */

/* For MultiSelect */
.ss-optgroup-actions{
  width: 100%;
  padding-left: 14px;
}
.ss-selectall {
  width: 100%;
  align-items: center;
}
.ss-selectall span{
  font-size: 14px !important;
}

.ss-main {
  padding-top: 9px;
  padding-bottom: 9px;
  @apply rounded-lg bg-gray-50;
}
.ss-content .ss-list .ss-option:hover[aria-selected='true']{
  background-color: #1d4ed8 !important
}
.ss-content .ss-list .ss-option:hover[aria-selected='false']{
  color: #4d4d4d;
  background-color: #f3f4f6 !important
}




/* STYLES FOR ALL INPUTS */

.input-read-value{
  @apply text-[14px] text-black;
}
.input-read-label{
  @apply text-sm text-gray-400;
}

.adaptive-input {
  height: 37px !important;
  font-size: 12px;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.ss-main{
  @apply py-[8.5px];
}
.ss-placeholder{
  font-size: 12px !important;
}
.ss-value-text{
  font-size: 12px !important;
}
.ss-selectall span{
  font-size: 12px !important;
}
.ss-option {
  width: 100%;
  padding: 7px;
  text-align: left;
  font-size: 12px;
}

.date-height, .form-control{
  height: 37px !important;
}
.form-control{
  @apply pl-3 font-medium;
  font-size: 12px !important;
  font-weight: 500 !important;
}

.largeHight-input-date{
  height: 50px !important;
  height: 50px !important;
}

.largeHight-input-date .date-height{
  height: 50px !important;
}

.largeHight-input-date input{
  border: #D1D5DB 1px solid !important;
}

.largeHight-input-date .form-control{
  height: 50px !important;
  padding-left: 35px !important;
}


@media (min-width: 1024px) and (max-width: 1799px) {
  .input-read-value{
    @apply text-[12px] text-black;
  }
  .input-read-label{
    @apply text-[9px] text-gray-400;
  }

  .date-height, .form-control{
    height: 37px !important;
  }
  .form-control{
    @apply pl-3;
    font-size: 12px !important;
  }
  .adaptive-input{
    font-size: 12px !important;
  }
  .read-only-input{
    font-size: 12px !important;
  }

  .ss-value-text{
    font-size: 12px !important;
  }
}

.note-editor.note-frame .note-editing-area .note-editable, .note-editor.note-airframe .note-editing-area .note-editable {
  padding: 10px;
  overflow: auto;
  word-wrap: break-word;
  height: 185px;
}

.note-editing-area .note-editable {
  height: 220px !important;
}

.ss-main{
  height: 37px !important;
}

/* .ss-main .ss-values .ss-value {
  margin-bottom: 11px;
} */


@media (min-width: 1700px) {
  .adaptive-input {
    height: 40px !important;
    font-size: 14px !important;
  }

  .ss-main{
    height: 40px !important;
  }
  .ss-placeholder{
    font-size: 14px !important;
  }
  .ss-value-text{
    font-size: 14px !important;
  }
  .ss-selectall span{
    font-size: 14px !important;
  }
  .ss-option {
    width: 100%;
    padding: 10px;
    text-align: left;
    font-size: 14px;
  }

  .date-height, .form-control{
    height: 40px !important;
  }
  .form-control{
    /* @apply pl-[30px]; */
    font-size: 13px !important;
  }

  .largeHight-input-date{
    height: 58px !important;
    height: 58px !important;
  }

  .largeHight-input-date .date-height{
    height: 58px !important;
  }

  .largeHight-input-date input{
    border: #D1D5DB 1px solid !important;
  }

  .largeHight-input-date .form-control{
    height: 58px !important;
    padding-left: 40px !important;
  }


}

@media (max-width:1800px) {
  .form-control, .form-control[readonly] {
    @apply pl-[30px];
  }
}
@media (min-width: 1024px) and (max-width: 1799px) {
  .form-control, .form-control[readonly] {
    @apply pl-[28px];
  }

}

.tag__text {
  font-size: 12px !important;
}


.borderNone.ss-main  {
  border: none !important;
}

.form-control:disabled {
  background-color: #e9ecef !important;
  opacity: 1;
}

.template-editor .note-editor.note-frame {
  border: 1px solid #ebedf2;
}

.poppins{
  font-family: 'Poppins' !important
}


/* .clickContent .gm-style-iw-t {
  bottom: 35px !important;
} */
.gm-style-iw-chr button {
  width: 18px !important;
  height: 20px !important;
}
.gm-style-iw-chr button span{
  margin: 6px 0px !important;
  width: 14px !important;
  height: 14px !important;
}

.custom-checkbox:focus {
  --tw-ring-inset: #000;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #000;
  --tw-ring-color: #000;
  --tw-ring-offset-shadow: #000 0 0 0 #000 #000;
  --tw-ring-shadow: #000 0 0 0 calc(1px + var(--tw-ring-offset-width)) #000;
}

.custom-checkbox:active {
  --tw-ring-inset: #000;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #000;
  --tw-ring-color: #000;
  --tw-ring-offset-shadow: #000 0 0 0 #000 #000;
  --tw-ring-shadow: #000 0 0 0 calc(1px + var(--tw-ring-offset-width)) #000;
}
.swiper-pagination-bullet-active {
  background-color: black !important;
}

.content-block app-notes .notes-list-wrapper app-note-list .note {
  background: #F3F4F6 !important;
  transition: 0.5s;
}

.content-block:hover app-notes .notes-list-wrapper app-note-list .note {
  background: #ffffff !important;
  transition: 0.5s;
}

.alert-warning{
  background-color: #fff3cd !important;
  border-color: #ffeeba !important;
  color: #856404 !important;
}

.flatpickr-input.active:focus {
  outline: none;
  box-shadow: none;
  border-color: transparent;
}

.dateStyle {
  height: 35px !important;
}
